import axios from "axios";
import React, { useState, useEffect } from "react";
import "./GarageRegistration.css";
import { Helmet } from "react-helmet";
import httpCommon from "../../http-common";


export default function GarageRegistration() {
  const [Mobile, setMobile] = useState(true);

  const [GarageInfo, setGarageInfo] = useState({});
  const URL = httpCommon.defaults.baseURL;
  const GarageTypes = [
    {
      id: 1,
      type: "Car",
    },
    {
      id: 2,
      type: "Bike",
    },
  ];

  const handleChangeForm = (e) => {
    setGarageInfo({ ...GarageInfo, [e.target.name]: e.target.value });
  };

  const handelShareMessage = (WhatsAppNumber) => {
    const date = new Date();
    const {
      owner,
      name,
      mobile_no,
      pincode,
      city,
      vehicle_type,
      branches_count,
      establishment_year,
    } = GarageInfo;
    let option = {
      method: "POST",
      headers: {
        "Content-Type": "text/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxMjZlYmUxNi04ZWE3LTQ3OWUtYmMyYi1hZTQ2MTVlMjZkNDMiLCJ1bmlxdWVfbmFtZSI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwibmFtZWlkIjoiZ2F0aXZhbmFwcEBnbWFpbC5jb20iLCJlbWFpbCI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMDQvMDQvMjAyMiAwNzowMTozNyIsImRiX25hbWUiOiI2NjA1IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQURNSU5JU1RSQVRPUiIsImV4cCI6MjUzNDAyMzAwODAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.1hWXiXp6C015_RxoES4Vf6Q6LOxpJxm1qzs-NIH9m5I",
      },
      body: JSON.stringify({
        receivers: [
          {
            customParams: [
              { name: "name", value: owner },
              { name: "garage_name", value: name },
              { name: "mobile_no", value: mobile_no },
              { name: "pincode", value: pincode },
              { name: "city", value: city },
              { name: "date", value: date },
              { name: "vehicle_type", value: vehicle_type },
              { name: "branches_count", value: branches_count },
              { name: "establishment_year", value: establishment_year },
            ],
            whatsappNumber: "+91" + WhatsAppNumber,
          },
        ],
        broadcast_name: "Gativan",
        template_name: "garage_registration_alert",
      }),
    };
    const url = (URL == 'https://testapi.gativan.in/api') ? "https://live-server-6605.wati.io/api/v1/sendTemplateMessages" : "";
    fetch(url, option)
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  };

  const handleSubmitForm = () => {
    const WhatsAppNumber = "8263090692";
   
{URL == 'https://testapi.gativan.in/api' ?  handelShareMessage(WhatsAppNumber) : handelShareMessage()}

    setGarageInfo({
      owner: "",
      name: "",
      mobile_no: "",
      pincode: "",
      city: "",
      vehicle_type: "",
      branches_count: "",
      establishment_year: "",
    });
  };

  const width = window.innerWidth;
  useEffect(() => {
    if (width <= 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);
  return (
    <>
      <Helmet>
        <title>
          Partner With Us - Gativan
        </title>
        <meta
          name="description"
          content="We at Gativan believe in joining hands with the right partners to provide high-quality services to our customers. In partnership, we believe in long run relationship."
        />
      </Helmet>
      <div className="garageRegistrationForm-container">
        <div className="garage-top-content">
          {Mobile ? (
            <img
              className="garage-hero"
              src={process.env.PUBLIC_URL + "/Assets/partnerUs/mobile-hero.png"}
              alt=""
            />
          ) : (
            <img
              className="garage-hero"
              src={process.env.PUBLIC_URL + "/Assets/partnerUs/hero.png"}
              alt=""
            />
          )}
          <div className="garage-top-content-left">
            <h4>Partner with us.</h4>
            <h4> Grow with us.</h4>
          </div>
          <div className="garage-top-content-right">
            <h6>Need Help? Contact Us: @8263090692</h6>
          </div>
        </div>

        <div className="garage-registrationForm-container">
          <div className="garage-registrationForm-top-content">
            Gativan Workshop in Pune
          </div>

          <div className="garage-registrationForm">
            <div className="garage-registrationFrom-header">
              <h4>
                Please fill this Garage Form to get listed in our Garage Partners:
              </h4>
            </div>

            <div className="garage-registrationForm-form">
              <input
                type="text"
                className="garage-registrationForm-fields"
                value={GarageInfo?.owner}
                name="owner"
                onChange={handleChangeForm}
                placeholder="Owner Name"
              />

              <input
                type="text"
                className="garage-registrationForm-fields"
                value={GarageInfo?.name}
                name="name"
                onChange={handleChangeForm}
                placeholder="Garage Name"
              />

              <input
                type="text"
                className="garage-registrationForm-fields"
                value={GarageInfo?.mobile_no}
                name="mobile_no"
                onChange={handleChangeForm}
                placeholder="Phone No."
              />

              <input
                type="text"
                className="garage-registrationForm-fields"
                value={GarageInfo?.city}
                name="city"
                onChange={handleChangeForm}
                placeholder="City"
              />

              <input
                type="text"
                className="garage-registrationForm-fields"
                value={GarageInfo?.pincode}
                name="pincode"
                onChange={handleChangeForm}
                placeholder="Zip"
              />

              <select
                className="garage-registrationForm-fields"
                name="vehicle_type"
                value={GarageInfo?.vehicle_type}
                onChange={handleChangeForm}
              >
                <option>Select Garage Type</option>
                {GarageTypes?.map((item) => (
                  <option value={item?.type} key={item.id}>
                    {item.type}
                  </option>
                ))}
              </select>

              <input
                type="number"
                className="garage-registrationForm-fields"
                value={GarageInfo?.branches_count}
                name="branches_count"
                onChange={handleChangeForm}
                placeholder="No.of branches"
              />

              <div className="garage-registrationForm-fields-Eyear">
                <label>Establishment Year:</label>
                <input
                  type="month"
                  value={GarageInfo?.establishment_year}
                  name="establishment_year"
                  onChange={handleChangeForm}
                />
              </div>

              <button
                className="garage-registrationForm-fields garage-registrationForm-submitBtn"
                onClick={handleSubmitForm}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
