import axios from "axios";

export default axios.create({
     /* main API */
        // baseURL:"https://testapi.gativan.in/api",

     /* dev API */
     baseURL: "https://devapi.gativan.in/api",

  /* local API */
  // baseURL: "http://localhost:5758/api",
  // baseURL: "http://10.10.2.32:5758/api",

  headers: {
    "Content-Type": "application/json",
  },
});
