import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { costomerDetail, getMobileNumber, handleOpenModalState, removeAllCart } from "../../action";
import httpCommon from "../../http-common";
import {
  getAllArrivalModes,
  getCategoryByCategoryId,
  getDateSlots,
  getOrderTypes,
  // getSlots,
  // getTimeSlots,
} from "../../services/vehicleservices/vehicleservices";
import ViewCartFooter from "../ReuseComponent/viewCartFooter/ViewCartFooter";
import ViewCart from "../vehicleserviceitems/Cart/ViewCart";
import ViewDetailedBill from "../viewDetailedBill/ViewDetailedBill";
import "./Checkout.css";
import { useRef } from "react";

export default function CheckOut() {
  const [MobileNumber, setMobileNumber] = useState();
  const [timeToggle, setTimeToggle] = useState();
  const [dateSlot, setDateSlot] = useState([]);
  const [timeSlot, setTimeSlot] = useState();
  const [dayAndDate, setDayAndDate] = useState({ day: "", date: "" });
  const [youPayAmount, setYouPayAmount] = useState(50);
  const [currentDate, setCurrentDate] = useState("");
  const [dateTimeSlot, setDateTimeSlot] = useState([]);
  const [TimeSlotsData, setTimeSlotsData] = useState([]);
  // const [bikeTimeSlot, setBikeTimeSlot] = useState([]);
  const [TimeDateSlot, setTimeDateSlot] = useState([]);
  const [city, setCity] = useState();
  const [address, setAddress] = useState({
    houseNo: "",
    area: "",
    city: "",
    pinCode: "",
    landMark: "",
    date: "",
    time: "",
  });

  const [isMobile, setIsMobile] = useState(false);
  const [isDateTimePicked, setIsDateTimePicked] = useState(false);
  const [isAddress, setIsAddress] = useState(false);
  const [arrivalModes, setArrivalModes] = useState([]);
  const [isInsurance, setIsInsurance] = useState({});
  const [allOrderType, setAllOrderType] = useState([]);
  const [orderType, setOrderType] = useState({});
  const [openDetailedBill, setOpenDetailedBill] = useState(false);

  let serviceDetails = [];
  // let date = +new Date();

  // const currTime = new Date().toLocaleTimeString();

  const date = new Date();

  // Get the current year, month, and day
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-based
  const day = date.getDate().toString().padStart(2, "0");
  // Format the date as yyyy-mm-dd
  const today = `${year}-${month}-${day}`;
  const showTime =
    date.getHours().toString().padStart(2, "0") +
    ":" +
    date.getMinutes().toString().padStart(2, "0") +
    ":" +
    date.getSeconds();

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const URL = httpCommon.defaults.baseURL;

  const width = window.innerWidth;

  const DateValidation = document.getElementById("date");
  const TimeSlotValidation = document.getElementById("time-slot");
  const methodElementRef = useRef(null);

  const contextData = useContext(AppContext);
  const {
    selectPaymentMethod,
    setIsCheckOut,
    setselectPaymentMethod,
    customerPickData,
    setCustomePickData,
  } = contextData;

  const scrollMethodToTop = (ele) => {
    const element = document.getElementById(ele);

    if (element) {
      window.scroll({
        top: document.body.offsetHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (isAddress) {
      scrollMethodToTop("payment-method");
    }

    if (isDateTimePicked) {
      scrollMethodToTop("address-section");
    }
  }, [isAddress, isDateTimePicked]);

  // ----------------------------------------------------------------
  const vehicleSelectionData = useSelector(
    (state) => state.SelectionVehicleReducer
  );
  let vehicleType = {
    id: vehicleSelectionData.vehicleType.id,
    vehicleName: vehicleSelectionData?.vehicleType?.vehicleName,
  };

  let brand = { id: vehicleSelectionData.brand.id };

  let model = { id: vehicleSelectionData.model.id };

  let variant = { id: vehicleSelectionData.variant.id };

  // --------------------------------------------------------------------------
  const servicesSelectionData = useSelector((state) => state.AddToCartReducer);
  let totalPrice = 0;
  servicesSelectionData.result.map((item, index) => {
    totalPrice += parseInt(item.actualPrice);
  });

  // --------------------------------------------------------------------------------
  const customerData = useSelector((state) => state.CustomerDetails_Reducer);

  // ---------------------------------------------------------------------------
  const ServiseData = useSelector((state) => state.AddToCartReducer);

  ServiseData?.result?.map((item) => {
    serviceDetails.push({ serviceId: item.serviceId });
    // if (item?.serviceCategoryId === 24 ) {
    // setIsInsurance(true)
    // }
  });

  useEffect(() => {
    ServiseData?.result?.map((item) => {
      if (item?.serviceCategoryId == 24  || item?.serviceCategoryId == 25) {
        getCategoryById(item?.serviceCategoryId);
      }
    });
  }, [ServiseData]);

  useEffect(() => {
    if (isInsurance?.category?.includes("Insurance")) {
      allOrderType?.filter((item) => {
        if (item?.type?.includes("Insurance")) {
          setOrderType(item);
        }
      });
    }else if (!isInsurance?.category?.includes("Insurance")) {
      allOrderType?.filter((item) => {
        if (item?.type?.includes("General")) {
          setOrderType(item);
        }
      });
    } else return false;
  }, [isInsurance, allOrderType]);

  // ---------------------------------------------------------------------------------
  const location = useSelector((state) => state.LocationReducer);
  const cityId = location.cityName?.toLowerCase()?.replaceAll("-"," ");

  


 

  // ---------------------------------------------------------------------------------------
  const customerMobile = useSelector((state) => state.MobileNumberReducer);
  // const mobileNumber = customerMobile.mobileNumber

  // get dates and slots
  const getDateAndSlots = async (currentDate) => {
    try {
      const response = await getDateSlots(currentDate, cityId);
      const data = await response.data;
      if (data.statusCode === 200 || data.statusCode === 0) {
        setDateTimeSlot(data.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const allArrivalModes = async () => {
    try {
      const response = await getAllArrivalModes();
      const data = await response.data;
      if (data.statusCode == 200) {
        setArrivalModes(data?.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCategoryById = async (categoryId) => {
    try {
      const response = await getCategoryByCategoryId(categoryId);
      const data = await response?.data;
      if (data?.statusCode == 200) {
        setIsInsurance(data?.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllOrderTypes = async () => {
    try {
      const response = await getOrderTypes();
      const data = await response?.data;
      if (data?.statusCode == 200) {
        setAllOrderType(data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const [data, setData] = useState([]);

  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const response = await axios.get(`${URL}/city/${location.cityName}`);
        setData(response.data.result);
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []); 


// console.log("idddddd",data.cityId)

const newCityId= (data.cityId)
// console.log(newCityId)

  const filteredArrivalModes = arrivalModes?.filter((item) => {
    return item.mode == "Pick up";
  });

  useEffect(() => {
    getDateAndSlots(moment(date).format("YYYY-MM-DD"));

    setDayAndDate({ ...dayAndDate, date: moment(date).format("YYYY-MM-DD") });

    setCurrentDate(moment(date).format("YYYY-MM-DD"));

    allArrivalModes();

    getAllOrderTypes();
  }, []);

  useEffect(() => {
    customerMobile?.mobileNumber &&
      setMobileNumber(customerMobile?.mobileNumber);
  }, [customerMobile.mobileNumber]);

  useMemo(() => {
    currentDate && getDateAndSlots(currentDate);
  }, [currentDate]);

  useMemo(() => {
    dateTimeSlot.filter((e) => {
      if (e.date === dayAndDate.date) {
        setTimeDateSlot(e?.timeSlotList);
        setDayAndDate({ day: e?.day, date: e?.date });
        setAddress({
          ...address,
          date: { day: e?.day, date: e?.date },
        });
      }
    });
  }, [dateTimeSlot]);

  useMemo(() => {
    if (MobileNumber?.length === 10) {
      dispatch(
        getMobileNumber({
          mobileNumber: MobileNumber,
        })
      );
    }
  }, [MobileNumber]);

  let arrayLength = 2;

  arrayLength = 6;

  let arr = new Array(arrayLength).fill(0);

  const UPIData = [
    {
      id: 1,
      img: "google pay.png",
      name: "Google Pay",
      value: "googlepay",
      gaCategory: "Payment",
      gaLabel: "UPI Payment",
      gaAction: "Pay with Google Pay UPI",
    },
    {
      id: 2,
      img: "paytm.png",
      name: "Paytm UPI",
      value: "paytm",
      gaCategory: "Payment",
      gaLabel: "UPI Payment",
      gaAction: "Pay with Paytm UPI",
    },
    {
      id: 3,
      img: "amazon pay.png",
      name: "Amazon UPI",
      value: "amazonupi",
      gaCategory: "Payment",
      gaLabel: "UPI Payment",
      gaAction: "Pay with Amazon UPI",
    },
    {
      id: 4,
      img: "phonepe.png",
      name: "PhonePe",
      value: "phonepe",
      gaCategory: "Payment",
      gaLabel: "UPI Payment",
      gaAction: "Pay with Phone Pe",
    },
  ];

  const PayAfterServiceData = [
    {
      id: 1,
      img: "Pay Online After Service.png",
      name: "Pay Online After Service",
      value: "",
      gaCategory: "Payment",
      gaLabel: "Pay After Service",
      gaAction: "Clicked on Pay Online After Service",
    },
    {
      id: 2,
      img: "Pay Cash After Service.png",
      name: "Pay Cash After Service",
      value: "",
      gaCategory: "Payment",
      gaLabel: "Pay After Service",
      gaAction: "Clicked on Pay Cash After Service",
    },
  ];

  const handelPaymentMethod = (gaCategory, gaLabel, gaAction) => {
    setselectPaymentMethod(true);
    document.getElementById("payment-option").style.display = "none";
    scrollToTop();
    ReactGA.event({
      category: gaCategory,
      action: gaAction,
      label: gaLabel,
    });
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const getBrowserIdRedux = useSelector((state) => state.SaveBrowserId);

  // ------------------- delete all cart ----------
  const deleteBrowserAllCart = async (browserId) => {
    try {
      const deleteCartData = await axios.delete(`${URL}/cart/${browserId}`);
      const result = await deleteCartData.data;
    } catch (err) {
      console.log(err);
    }
  };

  // ------------------web booking whatsapp message--------------------------

  const sendWhatsMessage = () => {
    const WhatsAppNumber = [" 8263090692", " 9168877462"];
    let mobileNumber = customerMobile?.mobileNumber;
    let preferredServiceDate = dayAndDate?.date;
    let preferredServiceTime = timeSlot;
    let houseNumber = address?.houseNo;
    let area = address?.area;
    let city = address?.city;
    let pinCode = address?.pinCode;
    let landMark = address?.landMark;
    let brand = vehicleSelectionData?.brand?.brandName;
    let model = vehicleSelectionData?.model?.modelName;
    let variant = vehicleSelectionData?.variant?.variantName;
    const vehicle = `${brand} ${model} ${variant}`;
    const addres = `${houseNumber}, ${area}, ${landMark},${city},${pinCode}`;
    const services = ServiseData.result?.map((i) => i.service).toString();

    WhatsAppNumber.map((WhatsAppNumber) => {
      let option = {
        method: "POST",
        headers: {
          "Content-Type": "text/json",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2M2NhYWFkMi1mZDAxLTQ4MDUtYmFjYi04ZjlkZjA5ODQ2ZjEiLCJ1bmlxdWVfbmFtZSI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwibmFtZWlkIjoiZ2F0aXZhbmFwcEBnbWFpbC5jb20iLCJlbWFpbCI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMTEvMjcvMjAyMyAwNDo1MDozNSIsImRiX25hbWUiOiI2NjA1IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQURNSU5JU1RSQVRPUiIsImV4cCI6MjUzNDAyMzAwODAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.VnSo4aCFuksqLSTYbqbW5F0IjqDe134cPO_9h1YhTX8",
        },
        body: JSON.stringify({
          receivers: [
            {
              customParams: [
                { name: "name", value: "New Customer" },
                {
                  name: "mobile_no",
                  value: mobileNumber ? mobileNumber : "null",
                },
                { name: "vehicle", value: vehicle ? vehicle : "null" },
                { name: "service", value: services ? services : "null" },
                { name: "address", value: address ? addres : "null" },
                {
                  name: "booking_slot_date",
                  value: preferredServiceDate ? preferredServiceDate : "null",
                },
                {
                  name: "booking_slot_time",
                  value: preferredServiceTime ? preferredServiceTime : "null",
                },
              ],
              whatsappNumber: "+91" + WhatsAppNumber,
            },
          ],
          broadcast_name: "Gativan",
          template_name: "new_booking_message_to_garage",
        }),
      };
      const url = (URL == 'https://testapi.gativan.in/api') ? "https://live-server-6605.wati.io/api/v1/sendTemplateMessages" : "";            
      fetch(url, option)
        .then((response) => response.json())
        .then((response) => console.log(response))
        .catch((err) => console.error(err));
    });
  };

  const sendWhatsMessageForCustomer = () => {
    let mobileNumber = customerMobile?.mobileNumber;
    let preferredServiceDate = dayAndDate?.date;
    let preferredServiceTime = timeSlot;
    let houseNumber = address?.houseNo;
    let area = address?.area;
    let city = address?.city;
    let pinCode = address?.pinCode;
    let landMark = address?.landMark;
    let brand = vehicleSelectionData?.brand?.brandName;
    let model = vehicleSelectionData?.model?.modelName;
    let variant = vehicleSelectionData?.variant?.variantName;
    const vehicle = `${brand} ${model} ${variant}`;
    const addres = `${houseNumber}, ${area}, ${landMark},${city},${pinCode}`;
    const services = ServiseData.result?.map((i) => i.service).toString();
    let option = {
      method: "POST",
      headers: {
        "Content-Type": "text/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2M2NhYWFkMi1mZDAxLTQ4MDUtYmFjYi04ZjlkZjA5ODQ2ZjEiLCJ1bmlxdWVfbmFtZSI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwibmFtZWlkIjoiZ2F0aXZhbmFwcEBnbWFpbC5jb20iLCJlbWFpbCI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMTEvMjcvMjAyMyAwNDo1MDozNSIsImRiX25hbWUiOiI2NjA1IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQURNSU5JU1RSQVRPUiIsImV4cCI6MjUzNDAyMzAwODAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.VnSo4aCFuksqLSTYbqbW5F0IjqDe134cPO_9h1YhTX8",
      },
      body: JSON.stringify({
        receivers: [
          {
            customParams: [
              { name: "vehicle", value: vehicle ? vehicle : "null" },
              { name: "service", value: services ? services : "null" },
              {
                name: "booking_slot_date",
                value: preferredServiceDate ? preferredServiceDate : "null",
              },
              {
                name: "booking_slot_time",
                value: preferredServiceTime ? preferredServiceTime : "null",
              },
            ],
            whatsappNumber: "+91" + mobileNumber,
          },
        ],
        broadcast_name: "Gativan",
        template_name: "web_bookings_april",
      }),
    };
    const url = (URL == 'https://testapi.gativan.in/api') ? 'https://live-server-6605.wati.io/api/v1/sendTemplateMessages' : '';
    fetch(url, option)
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  };

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }



    const options = {
      key: "rzp_test_rNraOyEwjVQ8a1", // Enter the Key ID generated from the Dashboard    {test: 'rzp_test_rNraOyEwjVQ8a1'} {live:"rzp_live_Qi5T2pB3Y1e2I8"}
      amount: youPayAmount * 100,
      currency: "INR",
      name: "Gativan",
      // description: "Test Transaction",
      // order_id: order_id,
      handler: async function (response) {
        const data = {
          user: {
            mobileNumber: customerMobile.mobileNumber,
          },
          servicesList: serviceDetails,
          bookingMode: 1,
          paymentId: response.razorpay_payment_id,
          address: {
            houseNumber: customerData?.houseNo?.toString(),
            country: "india",
            state: "maharashtra",
            district:  location?.cityName?.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ").replace(/-([a-z])/g, (_, letter) => `-${letter.toUpperCase()}`),
            tehsil: "",
            city:  location?.cityName?.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ").replace(/-([a-z])/g, (_, letter) => `-${letter.toUpperCase()}`),
            area: customerData?.area,
            pincode: customerData?.pinCode,
            landmark: customerData?.landMark,
            cityId: newCityId,
          },
          userVehicle: {
            brand: customerPickData?.brand?.id,
            model: customerPickData?.model?.id,
            vehicleType: customerPickData?.vehicleType?.id,
            variant: customerPickData?.variant?.id,
          },
          preferredServiceDate: customerData?.date?.date,
          preferredServiceTime: customerData?.time,
          arrivalDate: customerData?.date?.date,
          arrivalTime: customerData?.time,
          arrivalModeId: filteredArrivalModes[0]?.id,
          orderBookingMode: {
            modeId: 5,
          },
          remark: customerPickData?.remark ? customerPickData?.remark : "",
          orderTypeId: orderType?.id,
        };

        try {
          const result = await axios.post(`${URL}/orders`, data);
          const apiResponse = await result.data;
          if (String(apiResponse.statusCode)[0] == 2) {
            sendWhatsMessage();
            sendWhatsMessageForCustomer();
            deleteBrowserAllCart(getBrowserIdRedux);
            navigation("/ordersummary", { state: data });
            setCustomePickData({
              bookingMode: "Web",
              vehicleType: {
                id: "1",
                vehicleName: "Car",
              },
              brand: {
                id: "",
                brandName: "",
              },
              model: {
                id: "",
                modelName: "",
                imagePath: "",
              },

              variant: {
                id: "",
                variantName: "",
              },
            });
            dispatch(removeAllCart({ result: [] }));
            dispatch(handleOpenModalState(false));
            dispatch(
              getMobileNumber({
                mobileNumber: "",
              })
            );
          }
        } catch (error) {
          console.log(error);
        }

        // alert(result.data.msg);
        // navigation('/ordersummary')
      },
      prefill: {
        // name: "test",
        // email: "",
        contact: "+91" + MobileNumber,
      },
      // notes: {
      //     address: "Soumya Dey Corporate Office",
      // },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const handleDateSlot = (day, date, item) => {
    setDayAndDate({ day: day, date: date });
    item.timeSlotList && setTimeDateSlot(item.timeSlotList);
    setTimeToggle("");
    setTimeSlot("");
    setAddress({
      ...address,
      date: { day: day, date: date },
      time: "",
    });
    setIsDateTimePicked(false);
    setIsAddress(false);
  };

  const handelTimeSlot = (id, timeSlot) => {
    document.getElementById("time-slot").style.display = "none";
    setTimeToggle(timeSlot);
    setTimeSlot(timeSlot);
    setAddress({ ...address, time: timeSlot });

    if (timeSlot && dayAndDate.day) {
      // document.getElementById('time-slot').style.display = "none";
      setIsDateTimePicked(true);

      const element = document.getElementById("address-section");
      if (element) {
        window.scroll({
          top: document.body.offsetHeight,
          left: 0,
          behavior: "smooth",
        });
      }
    } else {
      // document.getElementById('time-slot').style.display = "block";
      setIsDateTimePicked(false);
    }
  };

  const getDateTime = async () => {
    try {
      const Response = await getDateSlots();
      const SlotsData = await Response.data;
      if (SlotsData.statusCode === 200) {
        setDateSlot(SlotsData.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTimeSlotsInfo = async () => {
    try {
      const request = await getDateSlots(cityId);
      const response = await request.data;
      setTimeSlotsData(response.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDateTime();
    getTimeSlotsInfo();
    setCity(cityId)
    // setIsCheckOut(false)
  }, [cityId]);

  const handelMobileNumber = (e) => {
    setMobileNumber(e.target.value);
    setIsMobile(false);
  };

  // ---------------------------- getting selected vehicle from redux -------------------
  const vehicleSelectedData = useSelector(
    (state) => state.SelectionVehicleReducer
  );

  // --------------------------- getting mobile number from redux ---------------------
  const getMobileNumberRedux = useSelector(
    (state) => state.MobileNumberReducer
  );

  const saveDataWBrowser = async (visitorId) => {
    const userDataObj = {
      browserId: visitorId,
      vehicleType: vehicleSelectedData?.vehicleType?.id,
      brandId: vehicleSelectedData?.brand?.id,
      modelId: vehicleSelectedData?.model?.id,
      variantId: vehicleSelectedData?.variant?.id,
      mobileNumber: getMobileNumberRedux?.mobileNumber,
      // cartItems: servicesSelectionData?.result
    };
    try {
      const saveCart = await axios.post(`${URL}/cart`, userDataObj);
      const result = await saveCart.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleContinueClick = () => {
    if (MobileNumber && MobileNumber?.length === 10) {
      setIsMobile(true);
      // call post api to save user data as per browser id
      getBrowserIdRedux && saveDataWBrowser(getBrowserIdRedux);
      document.getElementById("mobile-number-validation").style.display =
        "none";
    } else {
      setIsMobile(false);
      document.getElementById("mobile-number-validation").style.display =
        "block";
    }

    customerMobile.mobileNumber = MobileNumber;
  };

  const handelAddress = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
    setIsAddress(false);
  };

  const handelSubmitAddress = () => {
    switch (true) {
      case address.houseNo == "":
        document.getElementById("houseNo").style.display = "block";
        break;
      case address.houseNo != "":
        document.getElementById("houseNo").style.display = "none";
        break;
    }

    switch (true) {
      case address.area == "":
        document.getElementById("area").style.display = "block";
        break;
      case address.area != "":
        document.getElementById("area").style.display = "none";
        break;
    }

    switch (true) {
      case address.city == "":
        document.getElementById("city").style.display = "block";
        break;
      case address.city != "":
        document.getElementById("city").style.display = "none";
        break;
    }

    switch (true) {
      case address.pinCode == "":
        document.getElementById("pinCode").style.display = "block";
        break;
      case address.pinCode != "":
        document.getElementById("pinCode").style.display = "none";
        break;
    }

    if (
      address.houseNo != "" &&
      address.area != "" &&
      address.city != "" &&
      address.pinCode != ""
    ) {
      setIsAddress(true);
      // if (methodElementRef.current) {
      //   methodElementRef.current.scrollTo({
      //     top: 0,
      //     behavior: "smooth",
      //   });
      // }
    } else {
      setIsAddress(false);
    }
  };

  useEffect(() => {
    dispatch(costomerDetail(address));
  }, [address]);


  const cityName = location?.cityName?.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ").replace(/-([a-z])/g, (_, letter) => `-${letter.toUpperCase()}`);





  const postData = {
    user: {
      mobileNumber: customerMobile.mobileNumber,
    },
    servicesList: serviceDetails,
    bookingMode: 1,
    address: {
      houseNumber: customerData?.houseNo?.toString(),
      country: "india",
      state: "maharashtra",
      district: "pune",
      tehsil: "",
      city: cityName,
      area: customerData?.area,
      pincode: customerData?.pinCode,
      landmark: customerData?.landMark,
      cityId: 2,
    },
    userVehicle: {
      brand: customerPickData?.brand?.id,
      model: customerPickData?.model?.id,
      vehicleType: customerPickData?.vehicleType?.id,
      variant: customerPickData?.variant?.id,
    },
    preferredServiceDate: customerData?.date?.date,
    preferredServiceTime: customerData?.time,
    arrivalDate: customerData?.date?.date,
    arrivalTime: customerData?.time,
    arrivalModeId: filteredArrivalModes[0]?.id,
    orderBookingMode: {
      modeId: 5,
    },
    remark: customerPickData?.remark ? customerPickData?.remark : "",
    orderTypeId: orderType?.id,
  };

  const handlePayNow = () => {
    ReactGA.event({
      category: "Pay Now",
      action: "Clicked on Pay Now Desktop",
      label: "Pay Now",
    });

    switch (true) {
      case MobileNumber?.length != 10:
        document.getElementById("mobile-number-validation").style.display =
          "block";
        scrollToTop();
        document.getElementById("UPI-payment-radio-btn").checked = false;
        break;
      case MobileNumber?.length === 10:
        document.getElementById("mobile-number-validation").style.display =
          "none";
    }

    switch (true) {
      case address.time == "":
        document.getElementById("time-slot").style.display = "block";
        break;
      case address.time != "":
        document.getElementById("time-slot").style.display = "none";
        break;
    }

    switch (true) {
      case address.houseNo != "" &&
        address.area != "" &&
        address.city != "" &&
        address.pinCode != "":
        document.getElementById("houseNo").style.display = "none";
        document.getElementById("area").style.display = "none";
        document.getElementById("city").style.display = "none";
        document.getElementById("pinCode").style.display = "none";
        break;
      case address.houseNo == "" &&
        address.area == "" &&
        address.city == "" &&
        address.pinCode == "":
        document.getElementById("houseNo").style.display = "block";
        document.getElementById("area").style.display = "block";
        document.getElementById("city").style.display = "block";
        document.getElementById("pinCode").style.display = "block";
        break;
    }

    switch (true) {
      case selectPaymentMethod == true:
        document.getElementById("payment-option").style.display = "none";
        break;
      case selectPaymentMethod != true:
        document.getElementById("payment-option").style.display = "block";
        break;
    }

    if (
      MobileNumber?.length === 10 &&
      address.time != "" &&
      address.houseNo != "" &&
      address.area != "" &&
      address.city != "" &&
      address.pinCode != "" &&
      selectPaymentMethod == true
    ) {
      displayRazorpay();
    }
  };

  const handelPayAfterServicePaymentMethod = (
    event,
    gaCategory,
    gaLabel,
    gaAction
  ) => {
    event.preventDefault();
    if (MobileNumber && MobileNumber?.length === 10) {
      document.getElementById("mobile-number-validation").style.display =
        "none";
      postData.paymentMethod = "payAfterService";
      postData.paymentId = "Pay_After_Service";

      axios
        .post(`${URL}/orders`, postData)
        .then((response) => {
          if (response.status === 200 || String(response.status)[0] == 2) {
            sendWhatsMessage();
            sendWhatsMessageForCustomer();
            deleteBrowserAllCart(getBrowserIdRedux);
            dispatch(handleOpenModalState(false));
            navigation("/ordersummary", { state: postData });
            setCustomePickData({
              bookingMode: "Web",
              vehicleType: {
                id: "1",
                vehicleName: "Car",
              },
              brand: {
                id: "",
                brandName: "",
              },
              model: {
                id: "",
                modelName: "",
                imagePath: "",
              },

              variant: {
                id: "",
                variantName: "",
              },
            });
            dispatch(removeAllCart({ result: [] }));
            dispatch(
              getMobileNumber({
                mobileNumber: "",
              })
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (!MobileNumber || MobileNumber?.length !== 10) {
      scrollToTop();

      document.getElementById("pay-after-service-radio-btn").checked = false;

      document.getElementById("mobile-number-validation").style.display =
        "block";
    }

    ReactGA.event({
      category: gaCategory,
      action: gaAction,
      label: gaLabel,
    });
  };

  useEffect(() => {
    if (ServiseData.result.length == 0) {
      setYouPayAmount(0);
    } else if (ServiseData.result.length != 0) {
      setYouPayAmount(50);
    }
  }, [ServiseData]);

  //   handle View Detailed bill modal

  const openDetailedBillModal = () => {
    setOpenDetailedBill(true);
  };
  const closeDetailedBillModal = () => {
    setOpenDetailedBill(false);
  };

  return (
    <React.Fragment>
      {openDetailedBill && (
        <ViewDetailedBill
          open={openDetailedBill}
          close={closeDetailedBillModal}
          services={servicesSelectionData?.result}
          youPayAmount={youPayAmount}
        />
      )}
      <Box className="checkout-Container">
        {/*----------------------------CheckOut Details--------------*/}
        <Box className="checkout-details">
          {/*----------------Enter Mobile Number-------*/}
          <Box className="mobile-number">
            <h4 className="header">Enter Your Mobile Number</h4>
            <Box className="mobile-number-input" id="mobile-number-input">
              <span>+91</span>
              <input
                className="mobile-number-input-field"
                onChange={handelMobileNumber}
                maxLength="10"
                type="number"
                defaultValue={MobileNumber}
              />
            </Box>
            <span id="mobile-number-validation">
              * Please Enter Mobile Number
            </span>
            <Button
              sx={{
                pointerEvents: isMobile ? "none" : "all",
                opacity: isMobile ? 0.5 : 1,
              }}
              className="mobile-number-btn"
              onClick={handleContinueClick}
            >
              Continue
            </Button>
          </Box>
          {/*-----------Date and Time Slot------------*/}
          {isMobile === true ? (
            <Box className="date-time-slot">
              <h4 className="header">Select Prefered Time & Date</h4>
              <Box className="date-container">
                <Box className="date-header">
                  <CalendarMonthIcon />
                  <h4>Date</h4>{" "}
                  <span
                    id="date"
                    style={{
                      marginLeft: "5%",
                      fontSize: "15px",
                      color: "red",
                      display: "none",
                    }}
                  >
                    Please Select Date *
                  </span>
                </Box>
                <Box container>
                  <Box container className="date-slots-container">
                    <Box className="date-slots-subcontainer">
                      <Grid container rowSpacing={3} columns={15}>
                        {dateTimeSlot?.map((item, index) => {
                          const { day, date } = item;
                          let count = 0;

                          item?.timeSlotList?.map((i) => {
                            count += i.availableSlotsCount;
                          });

                          let result = date?.slice(8, 10);
                          return (
                            <Grid item xs={3.5} md={3} key={index}>
                              <Box
                                className="date-slots"
                                onClick={() => {
                                  handleDateSlot(day, date, item);
                                }}
                                sx={{
                                  backgroundColor:
                                    date == dayAndDate.date
                                      ? "#122164"
                                      : "#FFFFFF",
                                  color:
                                    date == dayAndDate.date
                                      ? "#FFFFFF"
                                      : "#000000",
                                  opacity:
                                    count == 0 &&
                                    vehicleType.vehicleName == "Bike"
                                      ? "0.5"
                                      : "1.0",
                                  pointerEvents:
                                    count == 0 &&
                                    vehicleType.vehicleName == "Bike"
                                      ? "none"
                                      : "auto",
                                }}
                              >
                                <h4>{day}</h4>
                                <h2>{result}</h2>
                              </Box>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box className="time-header">
                  <AccessTimeIcon className="time-clock-icon" />
                  <Box className="time-slots-header">
                    <h4>Pick Time Slot </h4>
                    {/* <h4 >  ( <span>6 slot Available</span> )</h4> */}
                  </Box>
                  <span
                    id="time-slot"
                    style={{
                      marginLeft: "5%",
                      fontSize: "15px",
                      color: "red",
                      display: "none",
                    }}
                  >
                    Please Select Time *
                  </span>
                </Box>
                <Box className="time-slot-container">
                  <Grid container rowSpacing={3} columns={15}>
                    {TimeDateSlot.map((item, index) => {
                      const StartTime = item.start_time
                        ? item.start_time?.slice(0, 5)
                        : item.startTime?.slice(0, 5);
                      const EndTime = item.end_time
                        ? item.end_time?.slice(0, 5)
                        : item.endTime?.slice(0, 5);
                      const timeSlot = `${StartTime}`;
                      return (
                        <Grid item xs={7.5} md={3} key={index}>
                          <Box
                            className="time-slots"
                            onClick={() => {
                              handelTimeSlot(item.id, timeSlot);
                            }}
                            sx={{
                              backgroundColor:
                                StartTime === timeToggle
                                  ? "#122164"
                                  : "#FFFFFF",
                              color:
                                StartTime === timeToggle
                                  ? "#FFFFFF"
                                  : "#000000",
                              opacity:
                                (dayAndDate?.date == today &&
                                  (item.startTime < showTime ||
                                    item.start_time < showTime))
                                  ? "0.5"
                                  : "1.0",

                              textDecoration:
                                (dayAndDate?.date == today &&
                                  (item.startTime < showTime ||
                                    item.start_time < showTime))
                                  ? "line-through"
                                  : "none",
                              pointerEvents:
                                (dayAndDate?.date == today &&
                                  (item.startTime < showTime ||
                                    item.start_time < showTime))
                                  ? "none"
                                  : "auto",
                            }}
                          >
                            <h4>
                              {StartTime} - {EndTime}
                            </h4>
                            {/* {vehicleType?.vehicleName === "Bike" ? (
                              item.availableSlotsCount &&
                              item.availableSlotsCount !== 0 ? (
                                <h6>
                                  {item.availableSlotsCount &&
                                    item.availableSlotsCount}{" "}
                                  slots available
                                </h6>
                              ) : (
                                <h6>NA</h6>
                              )
                            ) : (
                              ""
                            )} */}
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>

              {/* <Button className="date-time-btn" onClick={handelSubmitSlots}>
                Continue
              </Button> */}
            </Box>
          ) : (
            <Box className="service-type-empty-container">
              <h4>Select Date & Time of Service</h4>
            </Box>
          )}
          {/*--------------Enter Address-------------*/}
          {isDateTimePicked ? (
            <Box className="enter-address" id="address-section">
              <h4 className="header">Enter Your Address</h4>
              <Box>
                <input
                  className="address-house"
                  onChange={handelAddress}
                  name="houseNo"
                  placeholder="Enter House no., Building, Company, Appartment*"
                />
                <span
                  id="houseNo"
                  style={{
                    fontSize: "15px",
                    color: "red",
                    display: "none",
                  }}
                >
                  Please Fill The Address Above *
                </span>
                <input
                  className="address-area"
                  onChange={handelAddress}
                  name="area"
                  placeholder="Road Name, Area, Colony*"
                />
                <span
                  id="area"
                  style={{
                    fontSize: "15px",
                    color: "red",
                    display: "none",
                  }}
                >
                  Please Fill The Address Above *
                </span>
                <input
                  className="address-pincode"
                  onChange={handelAddress}
                  name="city"
                  placeholder="City*"
                />
                <span
                  id="city"
                  style={{
                    fontSize: "15px",
                    color: "red",
                    display: "none",
                  }}
                >
                  Please Fill The Address Above *
                </span>
                <input
                  type="number"
                  className="address-pincode"
                  onChange={handelAddress}
                  name="pinCode"
                  placeholder="Pincode*"
                />
                <span
                  id="pinCode"
                  style={{
                    fontSize: "15px",
                    color: "red",
                    display: "none",
                  }}
                >
                  Please Fill The Address Above *
                </span>
                <input
                  className="address-landmark"
                  onChange={handelAddress}
                  name="landMark"
                  placeholder="Enter Landmark (Optional)"
                />
              </Box>
              <Box className="save-address-checkbox">
                {/* <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Save address for future bookings"
                  />
                </FormGroup> */}
                <Button
                  className="use-this-address"
                  onClick={() => {
                    handelSubmitAddress();
                    // scrollMethodToTop();
                  }}
                  sx={{
                    pointerEvents: isAddress ? "none" : "all",
                    opacity: isAddress ? 0.5 : 1,
                  }}
                >
                  Continue
                </Button>
              </Box>
              {/* <Box className="or">
                            <h4 >Or</h4>
                        </Box>
                        <Button className="use-current-location" >
                            <img className="location-red-logo" src={process.env.PUBLIC_URL + "/Assets/checkoutpage/location red logo.png"} />
                            Use Current Location
                        </Button> */}
            </Box>
          ) : (
            <Box className="service-type-empty-container">
              <h4>Enter Your Address</h4>
            </Box>
          )}
          {/*----------Payment Method---------------*/}
          {isAddress == true ? (
            <Box
              id="payment-method"
              className="payment-method"
              ref={methodElementRef}
            >
              <h4 className="header">Select Payment Method</h4>
              <span
                id="payment-option"
                style={{
                  fontSize: "15px",
                  color: "red",
                  display: "none",
                }}
              >
                * Please Select Payment Option
              </span>
              {/*-------------UPI------------------*/}
              <Box className="payment-UPI">
                <h4 className="header">UPI</h4>
                <Box>
                  <ul className="payment-unordered-list" id="paymentMethod">
                    {UPIData.map((item) => {
                      const {
                        id,
                        img,
                        name,
                        value,
                        gaCategory,
                        gaLabel,
                        gaAction,
                      } = item;
                      return (
                        <li className="payment-list" key={id}>
                          <label className="upi-section">
                            <Box className="img-upi-section">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/Assets/checkoutpage/paymentsection/${img}`
                                }
                              />
                            </Box>
                            <Box className="label-upi-section">
                              <h4>{name}</h4>
                            </Box>
                            <Box
                              onClick={() =>
                                handelPaymentMethod(
                                  gaCategory,
                                  gaLabel,
                                  gaAction
                                )
                              }
                              className="radio-btn-upi-section"
                            >
                              <input
                                type="radio"
                                name="UPI"
                                id="UPI-payment-radio-btn"
                                value={value}
                              />
                            </Box>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </Box>
              </Box>
              {/*----------Pay After Service-------*/}
              <Box className="pay-after-Service">
                <h4 className="header">Pay After Service</h4>
                <Box>
                  <ul className="payment-unordered-list" id="paymentMethod">
                    {PayAfterServiceData.map((item) => {
                      const {
                        id,
                        img,
                        name,
                        value,
                        gaCategory,
                        gaLabel,
                        gaAction,
                      } = item;
                      return (
                        <li className="payment-list" key={id}>
                          <label className="upi-section">
                            <Box className="img-upi-section">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/Assets/checkoutpage/paymentsection/${img}`
                                }
                              />
                            </Box>
                            <Box className="label-upi-section">
                              <h4>{name}</h4>
                            </Box>
                            <Box
                              onClick={(event) =>
                                handelPayAfterServicePaymentMethod(
                                  event,
                                  gaCategory,
                                  gaLabel,
                                  gaAction
                                )
                              }
                              className="radio-btn-upi-section"
                            >
                              <input
                                type="radio"
                                id="pay-after-service-radio-btn"
                                name="UPI"
                                value={value}
                              />
                            </Box>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </Box>
              </Box>
              {/*--------Credit and Debit Card----*/}
              {/* <Box className='credit-debit-card'>
                            <h4 className="header" >Credit and Debit Card</h4>
                        </Box> */}
              {/*------------Wallets---------------*/}
              {/* <Box className='wallets'>
                            <h4 className="header" >Wallets</h4></Box> */}
              {/*-------------EMI------------------*/}
              {/* <Box className='emi'>
                            <h4 className="header" >EMI</h4>
                        </Box> */}
            </Box>
          ) : (
            <Box className="service-type-empty-container">
              <h4>Select Payment Method</h4>
            </Box>
          )}
          {/*------------- Note--------------------*/}
          {/* <Box className='note'>
                        <h4>Note</h4>
                    </Box> */}
        </Box>
        {/*----------------------View Cart Details--------------------*/}

        <Box className="view-cart-details">
          <ViewCart
            handlePayNow={handlePayNow}
            postData={postData}
            youPayAmount={youPayAmount}
          />
        </Box>
      </Box>
      <Box className="mobile-view-footer">
        {selectPaymentMethod ? (
          <ViewCartFooter
            buttonName="Pay Now"
            youPayAmount={youPayAmount}
            handlePayNow={handlePayNow}
            openDetailedBillModal={openDetailedBillModal}
          />
        ) : (
          ""
        )}
      </Box>
    </React.Fragment>
  );
}
